<dx-data-grid
  #grid
  [dataSource]="phxDataSource"
  class="gridContainer"
  [columns]="columns"
  [editing]="phxEditing"
  [summary]="{ totalItems: summary }"
  [height]="height"
  [allowColumnResizing]="true"
  [columnResizingMode]="configuration.columnHidingEnabled === true ? 'column' : 'widget'"
  [allowColumnReordering]="true"
  [columnHidingEnabled]="configuration.columnHidingEnabled"
  [columnChooser]="columnChooserConfig"
  [columnAutoWidth]="true"
  [showBorders]="configuration.showBorders"
  [showColumnLines]="true"
  [rowAlternationEnabled]="configuration.rowAlternationEnabled"
  [hoverStateEnabled]="true"
  [stateStoring]="stateStoringConfig"
  [masterDetail]="masterDetailConfig"
  [selectedRowKeys]="[]"
  [export]="exportConfig"
  [noDataText]="configuration.noDataText"
  [loadPanel]="loadPanelConfig"
  (onSelectionChanged)="onSelectionChanged($event)"
  (onCellClick)="onCellClick($event)"
  (onRowClick)="onRowClick($event)"
  (onRowExpanding)="onMasterRowExpanding($event)"
  (onToolbarPreparing)="onToolbarPreparing($event)"
  (onRowPrepared)="onRowPrepared($event)"
  (onCellPrepared)="onCellPrepared($event)"
  (onContextMenuPreparing)="onContextMenuPreparing($event)"
  (onEditorPreparing)="onEditorPreparing($event)"
  (onEditorPrepared)="onEditorPrepared($event)"
  (onContentReady)="onContentReady($event)"
  (onExporting)="exportToExcel($event)"
  [syncLookupFilterValues]="false"
>
  <dxo-selection
    [mode]="configuration.selectionMode"
    [selectAllMode]="configuration.selectAllMode"
    [allowSelectAll]="configuration.allowSelectAll"
    [showCheckBoxesMode]="configuration.showCheckBoxesMode"
    [deferred]="configuration.deferred"
  >
  </dxo-selection>
  <dxo-filter-row [visible]="configuration.showFilter"></dxo-filter-row>
  <dxo-header-filter [visible]="false"></dxo-header-filter>
  <dxo-search-panel
    [visible]="configuration.showSearch"
    [width]="200"
    placeholder="{{ 'common.generic.search' | phxTranslate }}..."
  ></dxo-search-panel>
  <dxo-group-panel
    [visible]="configuration.showGrouping"
    [emptyPanelText]="'common.phxDataTable.groupByDescription' | phxTranslate"
  ></dxo-group-panel>
  <dxo-grouping
    [autoExpandAll]="configuration.groupingAutoExpandAll"
    [expandMode]="configuration.groupingExpandMode"
    [contextMenuEnabled]="true"
    [allowCollapsing]="configuration.groupingAllowCollapsing"
  ></dxo-grouping>
  <dxo-sorting mode="multiple"></dxo-sorting>
  <dxo-scrolling
    [mode]="enableInfiniteScroll ? 'virtual' : 'standard'"
    showScrollbar="always"
    [useNative]="true"
  >
  </dxo-scrolling>
  <dxo-paging [pageSize]="configuration.pageSize"></dxo-paging>
  <dxo-pager
    [showPageSizeSelector]="false"
    [showInfo]="!enableInfiniteScroll"
    [showNavigationButtons]="!enableInfiniteScroll"
    [visible]="configuration.hidePager ? false : !enableInfiniteScroll"
  ></dxo-pager>
  <dxo-remote-operations
    [sorting]="true"
    [paging]="true"
    [filtering]="true"
    [summary]="true"
  ></dxo-remote-operations>
  <div
    class="custom-toolbar-content"
    *dxTemplate="let data of 'toolbarContent'"
  >
      <ng-content select="[toolbar]"></ng-content>
      <ng-container *ngIf="configuration.stateSavingMode !== PhxDataTableStateSavingMode.None">
      <dx-drop-down-button
        *ngIf="configuration.stateSavingMode === PhxDataTableStateSavingMode.Customizable"
        [text]="('common.phxDataTable.applyViewLabel' | phxTranslate) + ' ... '"
        (onInitialized)="onApplyViewDropDownInitialized($event)"
        dropDownContentTemplate="dropDownContentTemplate"
        class="apply-view-dropdown"
        [hint]="'common.phxDataTable.applyViewBtn' | phxTranslate"
      >
        <dxo-drop-down-options
          width="auto"
          minWidth="187"
          [wrapperAttr]="{ class: 'app-phx-data-table-apply-view' }"
        >
        </dxo-drop-down-options>

        <div *dxTemplate="let data of 'dropDownContentTemplate'">
          <ul class="actions-menu">
            <li
              class="dropdown-item"
              (click)="resetState()"
            >
              <a>{{ 'common.generic.reset' | phxTranslate }}</a>
              <i class="material-icons">replay</i>
            </li>
            <li
              class="divider dropdown-divider"
              *ngIf="states && states.length > 1"
            ></li>
            <li
              *ngIf="states && states.length <= 1"
              (click)="saveAsState()"
              class="dropdown-item"
            >
              <a>{{ 'common.phxDataTable.saveFirstViewBtn' | phxTranslate }}</a>
              <i class="material-icons">save</i>
            </li>

            <ng-container *ngFor="let state of states">
              <li
                *ngIf="state.StateName"
                class="dropdown-item"
              >
                <a (click)="applyState(state)">{{ state.StateName }}</a>
                <i
                  class="material-icons"
                  (click)="removeState(state)"
                  >clear</i
                >
              </li>
            </ng-container>
          </ul>
        </div>
      </dx-drop-down-button>
      <button
        id="resetButton"
        class="btn btn-default template-reset-button"
        (click)="resetState()"
        [title]="'common.generic.reset' | phxTranslate"
      >
        <i
          class="material-icons custom-tool-button"
          aria-hidden="true"
          >replay</i
        >
      </button>
      <button
        id="saveAsButton"
        *ngIf="configuration.stateSavingMode === PhxDataTableStateSavingMode.Customizable"
        class="btn btn-default template-save-button"
        (click)="saveAsState()"
        [title]="'common.phxDataTable.saveViewBtn' | phxTranslate"
      >
        <i
          class="material-icons custom-tool-button"
          aria-hidden="true"
          >save</i
        >
      </button>
    </ng-container>
    <button
      *ngIf="hasFilter && configuration.showFilter && configuration.showClearFilterButton"
      class="btn template-clear-filter-button"
      (click)="resetFilters()"
    >
      {{ 'common.phxDataTable.resetFilterBtn' | phxTranslate }}
      <i
        class="material-icons custom-tool-button"
        aria-hidden="true"
        >filter_list</i
      >
    </button>
  </div>
  <div *dxTemplate="let d of 'defaultDateCellTemplate'">
    <span>{{ d.value | date : dateColumnFormat }}</span>
  </div>
</dx-data-grid>

<div class="bottom-spacer"></div>

<div
  class="record-count-panel"
  *ngIf="enableInfiniteScroll && configuration.showTotalCount && (totalCount || currentCount)"
>
  <span>
    <span class="record-count-value">{{ currentCount }}</span> {{ 'common.phxDataTable.countOfTotalCountMessagePart1' | phxTranslate }} <span class="record-count-value">{{ totalCount }}</span>
    {{ 'common.phxDataTable.countOfTotalCountMessagePart2' | phxTranslate }}</span
  >
</div>

<pre *ngIf="debug">configuration: {{ configuration | json }}</pre>
<pre *ngIf="debug"
  >{{ defaultState.Id }} defaultState.StateName:{{ defaultState.StateName }} isSavingDefaultState:{{ isSavingDefaultState }} anyPendingSaveDefaultState:{{ anyPendingSaveDefaultState }}</pre
>
<pre *ngIf="debug">default state: {{ defaultState | json }}</pre>

<app-phx-modal
  #saveModal
  [buttons]="saveStateModalButtons"
  [fullScreen]="false"
  [title]="'common.phxDataTable.saveViewDialogTitle' | phxTranslate"
>
  <form
    #addStateForm="ngForm"
    novalidate
  >
    <div class="form-group">
      <label for="name">{{ 'common.phxDataTable.viewNameLabel' | phxTranslate }} *</label>
      <dx-select-box
        [items]="stateDetails"
        displayExpr="Name"
        [acceptCustomValue]="true"
        [searchEnabled]="true"
        required
        [(ngModel)]="selectedState"
        #name="ngModel"
        maxlength="128"
        minlength="3"
        id="name"
        name="name"
        [placeholder]="'common.phxDataTable.viewNamePlaceholder' | phxTranslate"
        (onValueChanged)="nameIsChanged = true; newStateDescription = selectedState.Description"
        (onCustomItemCreating)="addCustomItem($event)"
      ></dx-select-box>
    </div>
    <div class="form-group">
      <label for="description">{{ 'common.phxDataTable.viewDescriptionLabel' | phxTranslate }}</label>
      <textarea
        rows="3"
        class="form-control"
        id="description"
        name="description"
        [(ngModel)]="newStateDescription"
        maxlength="512"
      ></textarea>
    </div>
    <div
      *ngIf="name.errors && name.dirty && nameIsChanged"
      class="validation-error"
    >
      <div *ngIf="name.errors && name?.errors.required">
        {{ 'common.phxDataTable.viewNameRequired' | phxTranslate }}
      </div>
      <div *ngIf="name.errors && name?.errors.maxlength">
        {{ 'common.phxDataTable.viewNameMaxLength' | phxTranslate }}
      </div>
      <div *ngIf="name.errors && name?.errors.minlength">
        {{ 'common.phxDataTable.viewNameMinLength' | phxTranslate }}
      </div>
    </div>
  </form>
</app-phx-modal>
