// Disabled due to devextreme naming conventions
/* eslint-disable no-underscore-dangle */
import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { DxComponent, DxDataGridComponent, DxTemplateHost, WatcherHelper } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import * as moment from 'moment';
import * as _ from 'lodash';
import { PhxConstants } from '../..';
import { ApiService } from '../../services/api.service';
import {
  DialogResultType,
  PhxDataTableColumn,
  PhxDataTableConfiguration,
  PhxDataTableEditingConfig,
  PhxDataTableSelectionMode,
  PhxDataTableState,
  PhxDataTableStateDetailDeprecated,
  PhxDataTableStateSavingMode,
  PhxDataTableSummaryItem,
  PhxDataTableUserProfile
} from '../../model';
import { DialogService } from '../../services/dialog.service';
import { PhxDataTableService } from '../../services/phx-data-table.service';
import { PhxLocalizationService } from '../../services/phx-localization.service';
import { TransferState } from '@angular/platform-browser';
import DevExpress from 'devextreme/bundles/dx.all';
import { ReportAZSearchService } from '../../../report-azsearch/service/report-azsearch.service';
import { enableColumnChooserSort } from '../../utility/data-grid-column-chooser-sort';
import { ExportingEvent } from 'devextreme/ui/data_grid';
import { saveToExcel } from './utils/save-to-excel/save-to-excel.util';
import { LoadOptions } from 'devextreme/data';
import { InitializedEvent } from 'devextreme/ui/drop_down_button';

@Component({
  selector: 'app-phx-data-table',
  templateUrl: './phx-data-table.component.html',
  styleUrls: ['./phx-data-table.component.less']
})
export class PhxDataTableComponent extends DxComponent implements OnInit, AfterContentInit, AfterViewInit, AfterViewChecked, OnChanges, OnDestroy {
  @ViewChild('grid', { static: true }) grid: DxDataGridComponent;
  @ViewChild('addStateForm', { static: true }) addStateForm: any;
  @ViewChild('saveModal', { static: true }) saveModal: any;
  @Input() configuration: PhxDataTableConfiguration = new PhxDataTableConfiguration({});
  @Input() columns: Array<PhxDataTableColumn>;
  @Input() summary: Array<PhxDataTableSummaryItem>;
  @Input() enableInfiniteScroll = false;

  @Input() dataSource: any;
  @Input() dataSourceUrl: string;
  @Input() dataSourceParams: string;

  @Input() componentName = '';
  @Input() exportFileName = '';
  @Input() debug = false;

  @Input() height = 'auto';
  @Input() dataStoreKey: string | string[];
  @Input() defaultStateName = '';
  @Input() editing: PhxDataTableEditingConfig;

  @Output() responseReceived: EventEmitter<any> = new EventEmitter<any>();
  @Output() editorPreparing: EventEmitter<any> = new EventEmitter<any>();
  @Output() editorPrepared: EventEmitter<any> = new EventEmitter<any>();
  @Output() contentReady: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() cellClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowPrepared: EventEmitter<any> = new EventEmitter<any>();
  @Output() cellPrepared: EventEmitter<any> = new EventEmitter<any>();
  @Output() masterRowExpanding: EventEmitter<any> = new EventEmitter<any>();
  @Output() loadingStarted: EventEmitter<any> = new EventEmitter<any>();
  @Output() contextMenuPreparing: EventEmitter<any> = new EventEmitter<any>();
  @Output() contextMenuOpenTab: EventEmitter<any> = new EventEmitter<any>();
  @Output() exporting = new EventEmitter<ExportingEvent>();

  initState: PhxDataTableState;
  defaultState: PhxDataTableUserProfile;
  states: Array<PhxDataTableUserProfile>;
  lastDefaultState: string;
  isSavingDefaultState = false;
  anyPendingSaveDefaultState = false;
  PhxDataTableStateSavingMode = PhxDataTableStateSavingMode;
  dateColumnFormat = PhxConstants.DateFormat.mediumDate;

  phxEditing: any = {};

  selectedState: PhxDataTableStateDetailDeprecated = { Name: '', Description: '', Id: 0 };
  nameIsChanged = false;
  newStateDescription = '';
  stateDetails: PhxDataTableStateDetailDeprecated[] = [];
  totalCount = 0;
  currentCount = 0;

  phxDataSource: any = {};
  hasFilter = false;

  exportConfig = { enabled: this.configuration.enableExport, fileName: this.exportFileName };

  columnChooserConfig = {
    enabled: this.configuration.showColumnChooser,
    width: 300,
    height: 350,
    title: 'common.phxDataTable.columnChooserTitle',
    emptyPanelText: 'common.phxDataTable.columnChooserEmptyPanelText',
    mode: 'select',
    allowSearch: true
  };

  saveStateModalButtons = [];

  isInitialSave = true;
  stateStoringConfig = {
    enabled: true,
    type: 'custom',
    storageKey: this.componentName,
    customLoad: () => {},
    customSave: gridState => {
      /** The initial state is what was loaded from API or the default, no need to save it */
      if (!this.isInitialSave) {
        this.customSave(gridState);
      } else {
        this.isInitialSave = false;
      }
    },
    savingTimeout: 500
  };

  masterDetailConfig = {
    enabled: this.configuration.enableMasterDetail,
    template: this.configuration.masterDetailTemplateName,
    autoExpandAll: this.configuration.masterDetailAutoExpandAll
  };

  loadPanelConfig = {
    enabled: 'auto',
    height: 90,
    indicatorSrc: './../../../../assets/loading.gif',
    showIndicator: true,
    showPane: true,
    text: this.configuration.loadPanelText,
    width: 200
  };

  private combinedFilter: any;

  private hasInited = false;
  private applyViewDropdownButton?: DevExpress.ui.dxDropDownButton;

  constructor(
    private eRef: ElementRef,
    ngZone: NgZone,
    private templateHost: DxTemplateHost,
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _watcherHelper: WatcherHelper,
    private dataTableService: PhxDataTableService,
    private apiService: ApiService,
    private dialogService: DialogService,
    private locale: PhxLocalizationService,
    transferState: TransferState,
    private reportService: ReportAZSearchService,
    @Inject(PLATFORM_ID) platformId: any
  ) {
    super(eRef, ngZone, templateHost, _watcherHelper, transferState, platformId);
    this.saveStateModalButtons = [
      {
        icon: 'save',
        tooltip: locale.translate('common.generic.save'),
        btnType: 'primary',
        btnClasses: ['customToolbarButton'],
        action: () => {
          this.addState();
        },
        disabled: () => !this.addStateForm.valid
      },
      {
        icon: 'clear',
        tooltip: locale.translate('common.generic.cancel'),
        btnType: 'default',
        btnClasses: ['customToolbarButton'],
        action: () => {
          this.cancelSaveState();
        }
      }
    ];
  }

  public getDataSource() {
    return this.grid.instance.getDataSource();
  }

  public getSelectedRowsData(): any {
    if (this.grid && this.grid.instance) {
      return this.grid.instance.getSelectedRowsData();
    }
    return null;
  }

  public refresh() {
    return this.grid.instance.refresh();
  }

  public updateDimensions() {
    this.grid.instance.updateDimensions();
  }

  public clearSelection() {
    this.grid.instance.clearSelection();
  }

  public beginUpdate() {
    this.grid.instance.beginUpdate();
  }

  public endUpdate() {
    this.grid.instance.endUpdate();
  }

  ngOnInit() {
    enableColumnChooserSort();
    this.applyLocalization();

    this.defaultState = this.dataTableService.createEmptyPhxDataTableUserProfile(this.componentName);
    if (this.configuration.stateSavingMode !== PhxDataTableStateSavingMode.None) {
      this.loadStates().then(() => {
        this.phxDataSource = this.buildDataSource(this.phxDataSource);
      });
    } else {
      this.phxDataSource = this.buildDataSource(this.phxDataSource);
    }
    this.phxEditing = this.editing || {};

    this.rebindConfiguration();

    this.hasInited = true;
  }

  ngAfterContentInit(): void {
    this.templates.forEach(template => this.grid.templates.push(template));
  }

  ngAfterViewChecked() {
    if (this.instance) {
      super.ngAfterViewChecked();
    }
  }

  ngAfterViewInit(): void {
    if (this.instance) {
      super.ngAfterViewInit();
    }
    const columnChooserView = (this.grid.instance as any).getView('columnChooserView');
    if (!columnChooserView._popupContainer) {
      columnChooserView._initializePopupContainer();
      columnChooserView.render();
      columnChooserView._popupContainer.option('position', { my: 'center', at: 'center' });
      columnChooserView._popupContainer.option('onShown', () => {
        this.beginUpdate();
      });
      columnChooserView._popupContainer.option('onHidden', () => {
        this.endUpdate();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.hasInited) {
      if (changes.exportFileName || (changes.configuration && changes.configuration.currentValue)) {
        this.rebindConfiguration();
      }

      if (changes.dataSource || changes.dataSourceUrl || changes.dataSourceParams) {
        let isNeedRefresh = false;
        if (changes.dataSourceParams) {
          isNeedRefresh = true;
        }
        if (changes && changes.dataSourceUrl && changes.dataSourceUrl.currentValue) {
          this.dataSourceUrl = changes.dataSourceUrl.currentValue;
          isNeedRefresh = true;
        }

        this.rebindDataSource();

        if (isNeedRefresh) {
          void this.grid.instance.refresh();
        }
      }
    }
  }

  ngOnDestroy() {
    this.grid.instance.hideColumnChooser();
  }

  onApplyViewDropDownInitialized = (e: InitializedEvent) => {
    this.applyViewDropdownButton = e.component;
  };

  // Override of devextreme method
  // eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-unused-vars
  _createInstance(element: any, options: any) {
    return this.grid.instance;
  }

  applyLocalization() {
    this.columnChooserConfig.title = this.locale.translate(this.columnChooserConfig.title);
    this.columnChooserConfig.emptyPanelText = this.locale.translate(this.columnChooserConfig.emptyPanelText);
  }

  rebindConfiguration() {
    this.exportConfig.fileName = this.exportFileName;
    this.exportConfig.enabled = this.configuration.enableExport;
    this.columnChooserConfig.enabled = this.configuration.showColumnChooser;
    this.masterDetailConfig.enabled = this.configuration.enableMasterDetail;
    this.masterDetailConfig.template = this.configuration.masterDetailTemplateName;
    this.masterDetailConfig.autoExpandAll = this.configuration.masterDetailAutoExpandAll;
    this.loadPanelConfig.text = this.configuration.loadPanelText || this.locale.translate('common.phxDataTableConfiguration.loadPanelText');
    this.loadPanelConfig.enabled = this.configuration.loadPanelEnabled;

    if (!this.configuration.noDataText) {
      this.configuration.noDataText = this.locale.translate('common.phxDataTableConfiguration.noDataText');
    }
  }

  rebindDataSource() {
    this.totalCount = 0;
    this.currentCount = 0;
    this.phxDataSource = this.buildDataSource(this.phxDataSource);
  }

  showPopup() {
    this.saveModal.show();
  }

  hidePopup() {
    this.saveModal.hide();
  }

  onEditorPreparing(event) {
    this.reportService.onEditorPreparing(event);
    this.editorPreparing.emit(event);
  }

  onEditorPrepared(event) {
    this.editorPrepared.emit(event);
  }

  onContentReady(event) {
    this.hasFilter = this.reportService.handleFilters(this.grid.instance);

    if (Array.isArray(this.dataSource)) {
      this.currentCount = this.totalCount = this.grid.instance.getDataSource().totalCount();
    }

    if (this.configuration && this.configuration.selectionMode && this.configuration.selectionMode !== PhxDataTableSelectionMode.None) {
      const newFilter = this.grid.instance.getCombinedFilter();
      if (!this.reportService.compareFilters(newFilter, this.combinedFilter)) {
        this.grid.instance.clearSelection();
      }
      this.combinedFilter = newFilter;
    }

    this.contentReady.emit(event);
  }

  onSelectionChanged(event) {
    this.selectionChanged.emit(event);
  }

  onRowClick(event: any) {
    this.rowClick.emit(event);
  }

  onMasterRowExpanding(event: any) {
    this.masterRowExpanding.emit(event);
  }

  onCellClick(event: any) {
    this.cellClick.emit(event);
  }

  onRowPrepared(event: any) {
    if (this.configuration.rowHighlightingConfig) {
      if (event.rowType === 'data' && event.data[this.configuration.rowHighlightingConfig.fieldName]) {
        event.rowElement.classList.add(this.configuration.rowHighlightingConfig.cssClass);
      }
    }
    this.rowPrepared.emit(event);
  }

  onCellPrepared(event: any) {
    this.cellPrepared.emit(event);
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      template: 'toolbarContent'
    });
    if (this.configuration.showToolbar === false) {
      e.toolbarOptions.visible = false;
    }
  }

  onContextMenuPreparing(event: any) {
    this.contextMenuPreparing.emit(event);
    if (event && event.row && event.row.rowType === 'data' && this.configuration.showOpenInNewTab) {
      event.items = [
        {
          text: this.locale.translate('common.phxDataTable.contextMenuOpenNewTab'),
          onItemClick: () => {
            this.contextMenuOpenTab.emit(event.row.data);
          }
        }
      ];
    }
  }

  exportToExcel(event: ExportingEvent): void {
    this.exporting.emit(event);
    event.fileName = this.exportFileName;
    void saveToExcel(event, this.configuration.customizeExportData);
  }

  resetFilters() {
    const grid = this.grid ? this.grid.instance : null;
    const columnCount = grid ? grid.columnCount() : 0;
    if (grid) {
      grid.clearFilter();
      for (let i = 0; i < columnCount; i++) {
        grid.columnOption(i, 'selectedFilterOperation', undefined);
      }
    }
  }

  applyState(state) {
    if (state != null) {
      this.selectedState = { Id: state.Id, Name: state.StateName, Description: state.StateDescription };
      if (this.addStateForm) {
        this.addStateForm.form.markAsPristine();
      }
      this.loadGridFromState(state.State);
    }
    void this.applyViewDropdownButton?.close();
  }

  addCustomItem(data) {
    const newItem: PhxDataTableStateDetailDeprecated = { Id: 0, Name: data.text, Description: '' };
    if (data.text.length >= 3 && data.text.length <= 128) {
      this.stateDetails.push(newItem);
      data.customItem = newItem;
    } else {
      this.newStateDescription = '';
    }
  }

  cancelSaveState() {
    this.hidePopup();
    this.selectedState = { Id: 0, Name: '', Description: '' };
    this.nameIsChanged = false;
    this.addStateForm.form.markAsPristine();
  }

  addState() {
    if (this.selectedState) {
      const gridState = this.grid.instance.state();
      let stateToSave: PhxDataTableUserProfile;

      const index = this.states.findIndex(item => item.StateName === this.selectedState.Name);
      if (index > -1) {
        stateToSave = this.states[index];
      } else {
        stateToSave = this.dataTableService.createEmptyPhxDataTableUserProfile(this.componentName);
      }
      stateToSave.StateName = this.selectedState.Name;
      stateToSave.StateDescription = this.newStateDescription;
      stateToSave.State = gridState;
      stateToSave.ComponentName = this.componentName;

      this.doSaveState(stateToSave).then(() => {
        this.hidePopup();
        this.addStateForm.form.markAsPristine();
      });
    }
  }

  removeState(state) {
    this.dialogService.confirmDelete().then(button => {
      if (button === DialogResultType.Yes) {
        const stateToRemove = state;
        this.dataTableService
          .removeState(stateToRemove)
          .then(() => {
            this.states.splice(
              this.states.findIndex(item => item.Id === stateToRemove.Id),
              1
            );

            if (this.states.length > 0) {
              this.defaultState = this.states[0];
            }

            this.populateStateNames();
          })
          .catch(() => {});
      }
    });
    void this.applyViewDropdownButton?.close();
  }

  customSave(gridState: any) {
    delete gridState.selectedRowKeys;
    this.defaultState.State = gridState;
    if (JSON.stringify(gridState) !== this.lastDefaultState) {
      if (this.isSavingDefaultState === false) {
        this.saveDefaultState();
      } else {
        this.anyPendingSaveDefaultState = true;
      }
    }
  }

  saveDefaultState() {
    if (this.configuration.stateSavingMode === PhxDataTableStateSavingMode.None) {
      return;
    }
    this.isSavingDefaultState = true;
    const stateToSave = Object.assign({}, this.defaultState);

    if (this.configuration.saveUserFilters === false && stateToSave.State.columns) {
      stateToSave.State.columns.forEach(column => {
        delete column.selectedFilterOperation;
        delete column.filterValue;
        delete column.filterValues;
      });
      delete stateToSave.State.searchText;
    }

    this.lastDefaultState = JSON.stringify(stateToSave.State);

    this.doSaveState(stateToSave).then(
      state => {
        this.isSavingDefaultState = false;
        this.defaultState = state;

        if (this.anyPendingSaveDefaultState === true) {
          this.anyPendingSaveDefaultState = false;
          this.customSave(this.grid.instance.state());
        }
      },
      () => {
        this.isSavingDefaultState = false;
        if (this.anyPendingSaveDefaultState === true) {
          this.anyPendingSaveDefaultState = false;
          this.customSave(this.grid.instance.state());
        }
      }
    );
  }

  saveAsState() {
    this.showPopup();
    void this.applyViewDropdownButton?.close();
  }

  resetState() {
    this.dialogService.confirm(this.locale.translate('common.phxDataTable.confirmResetViewTitle'), this.locale.translate('common.phxDataTable.confirmResetViewMessage')).then(button => {
      if (button === DialogResultType.Yes) {
        if (this.initState) {
          this.loadGridFromState(this.initState);
        }
      }
    });
    void this.applyViewDropdownButton?.close();
  }

  public getSumOf(fieldName: string): Promise<number> {
    return new Promise(resolve => {
      if (this.configuration.deferred) {
        this.grid.instance.getSelectedRowsData().then((selectedData: [any]) => {
          resolve(selectedData.reduce((a, b) => a + b[fieldName], 0));
        });
      } else {
        resolve(this.grid.instance.getSelectedRowsData().reduce((a, b) => a + b[fieldName], 0));
      }
    });
  }

  private populateStateNames() {
    this.stateDetails = this.states
      .filter(i => i.StateName !== '')
      .map(i => {
        return {
          Id: i.Id,
          Name: i.StateName,
          Description: i.StateDescription
        };
      });
  }

  private loadGridFromState(state: PhxDataTableState) {
    // Keep the original state at the begining
    if (this.initState == null) {
      this.initState = this.grid.instance.state();
    }

    if (state.columns && state.columns.length && this.columns && this.columns.length) {
      state.columns.forEach(c => {
        if (c && c.visible && !this.columns.some(col => col.dataField === c.dataField && (col.visible || col.showInColumnChooser))) {
          c.visible = false;
        }
      });
    }

    this.grid.instance.state(state);
    if (this.configuration && this.configuration.selectionMode && this.configuration.selectionMode !== PhxDataTableSelectionMode.None) {
      this.grid.instance.clearSelection();
    }
    void this.grid.instance.refresh();
  }

  private loadStates() {
    return this.dataTableService
      .getStates(this.componentName)
      .then(data => {
        this.states = data.Items;
        if (this.states.length > 0) {
          let stateToApply: PhxDataTableState;
          const defaultStateIndex = this.states.findIndex(item => item.StateName === this.defaultStateName);
          if (defaultStateIndex > -1) {
            this.defaultState = this.states[defaultStateIndex];
            stateToApply = this.states[defaultStateIndex].State;
          } else {
            stateToApply = this.states[0].State;
          }
          this.loadGridFromState(stateToApply);
        }

        this.populateStateNames();
      })
      .catch(() => {});
  }

  private doSaveState(state: PhxDataTableUserProfile): Promise<PhxDataTableUserProfile> {
    return new Promise((resolve, reject) => {
      this.dataTableService
        .saveState(state)
        .then(() => {
          this.dataTableService
            .getState(state.ComponentName, state.StateName)
            .then(res => {
              if (res.Items && res.Items.length > 0) {
                const dbState = res.Items[0];
                if (!this.states) {
                  this.states = [];
                }
                // new state
                if (state.Id === 0) {
                  this.states.push(dbState);
                } else {
                  const exisitingStateIndex = this.states.findIndex(item => item.Id === dbState.Id);
                  if (exisitingStateIndex !== -1) {
                    this.states[exisitingStateIndex].State = dbState.State;
                    this.states[exisitingStateIndex].LastModifiedDatetime = dbState.LastModifiedDatetime;
                  } else {
                    this.states.push(dbState);
                  }
                }
                resolve(dbState);
              } else {
                resolve(null);
              }
              this.populateStateNames();
            })
            .catch(ex => {
              reject(ex);
            });
        })
        .catch(ex => {
          reject(ex);
        });
    });
  }

  private buildDataSource(dataSource: any) {
    if (this.dataSource && Array.isArray(this.dataSource)) {
      this.totalCount = this.dataSource.length;
      dataSource = new DataSource({
        store: new ArrayStore({
          data: this.dataSource,
          key: this.dataStoreKey,
          onLoaded: () => {}
        })
      });
    } else if (this.dataSource) {
      dataSource = this.dataSource;
    } else if (this.dataSourceUrl) {
      this.summary = null;
      dataSource = { ...dataSource, store: this.createCustomDataSource() };
    } else {
      dataSource = [];
    }
    return dataSource;
  }

  private createCustomDataSource(): CustomStore {
    return new CustomStore({
      key: Array.isArray(this.dataStoreKey) ? this.dataStoreKey : undefined,

      load: loadOptions => {
        this.loadingStarted.emit();
        let params = '';
        if (this.dataSourceParams && this.dataSourceParams !== '') {
          params += this.dataSourceParams;
        }

        // Removed isLoadingAll check and default values as per https://github.com/DevExpress/devextreme-angular/issues/522#issuecomment-321902314
        if (loadOptions.skip != null) {
          params += '&$skip=' + loadOptions.skip;
        }
        if (loadOptions.take != null) {
          params += '&$top=' + loadOptions.take;
        }

        if (loadOptions.sort) {
          params += '&$orderby=' + loadOptions.sort[0].selector;
          if (loadOptions.sort[0].desc) {
            params += ' desc';
          }
        }

        const filterString = this.buildFilter(loadOptions);
        if (filterString !== '') {
          if (params.includes('$filter')) {
            params = params.replace(/\$filter=([^&]*)(&?)/, '$filter=(' + filterString + ') and ($1)$2');
          } else {
            params += '&$filter=' + filterString;
          }
        }
        params += '&$inlinecount=allpages';

        if (params.length) {
          if (params[0] === '&') {
            params = params.substr(1);
          }
          params = '?' + params;
        }

        const queryLink = this.dataSourceUrl + params;
        return this.apiService
          .queryWithPromise(queryLink, false)
          .then((response: any) => {
            if (this.totalCount !== response.Count) {
              // when refresh page or filtered
              this.currentCount = 0;
            }

            this.totalCount = response.Count || 0;
            if (loadOptions.skip + loadOptions.take <= response.Count) {
              const maxCount = this.currentCount;
              if (loadOptions.skip + loadOptions.take > maxCount) {
                this.currentCount = loadOptions.skip + loadOptions.take;
              } else {
                this.currentCount = maxCount;
              }
            } else {
              this.currentCount = response.Count || 0;
            }
            this.responseReceived.emit(response.Items);
            const result: any = {
              data: response.Items || [],
              count: response.Items.length || 0
            };

            if (loadOptions.requireTotalCount) {
              result.totalCount = this.totalCount;
            }

            return result;
          })
          .catch(() => {
            throw new Error($localize`:@@common.error.dataLoading:Data could not be loaded`);
          });
      }
    });
  }

  private buildFilter(loadOptions: LoadOptions): string {
    let filterString = '';
    if (loadOptions.filter) {
      const filters = [];
      this.convertSingleFilterToArrayOfFilters(loadOptions.filter, filters);

      filters.forEach(data => {
        if (typeof data === 'string') {
          // it's an operator ex 'and'
          filterString += ` ${data} `;
        } else if (this.isFilterArray(data)) {
          const filterColumn = data[0];
          const operator = data[1];
          const value: any = data[2];

          let gridColumn = null;
          if (data.columnIndex) {
            gridColumn = this.grid.columns[data.columnIndex];
          } else {
            const filteredColumns = this.grid.columns.filter(i => (i as DevExpress.ui.dxDataGrid.Column).dataField === filterColumn);
            if (filteredColumns.length > 0) {
              gridColumn = filteredColumns[0];
            }
          }

          filterString += this.convertToODataFilter(filterColumn, operator, value, gridColumn ? gridColumn.dataType : 'string', gridColumn ? gridColumn.isArray : false);
        }
      });
    }

    return filterString;
  }

  private isFilterArray(data: any) {
    return _.isArray(data);
  }

  private convertSingleFilterToArrayOfFilters(filter: any, filters: Array<any> = []) {
    // single filter is a simple array of field operator value, and it has columnIndex
    // multiple filter is an array of singlefilter arrays with `and` operator
    // single between filter is an array of singlefilter arrays with `and` operator (less than, greater than) and it has columnIndex

    if (this.isFilterArray(filter) && filter.length > 0) {
      if (this.isFilterArray(filter[0]) && filter.columnIndex == null) {
        // this is multiple filter
        filter.forEach(element => {
          this.convertSingleFilterToArrayOfFilters(element, filters);
        });
      } else if (this.isFilterArray(filter[0]) && filter.columnIndex != null) {
        // this is between filter
        filters.push('(');
        filter.forEach(element => {
          if (this.isFilterArray(element) && !element.columnIndex && !!filter.columnIndex) {
            element.columnIndex = filter.columnIndex;
          }
          filters.push(element);
        });
        filters.push(')');
      } else {
        filters.push(filter);
      }
    } else {
      // this is an operator
      filters.push(filter);
    }
  }

  private getODataValue(value: any, dataType: string): string {
    if (value instanceof Date) {
      return `datetime\'${moment(value.toISOString()).format()}\'`;
    } else if (typeof value === 'string' && dataType === 'date' && this.isDate(value)) {
      return `datetime\'${new Date(moment(value).format('YYYY-MM-DD') + 'T00:00:00.000Z').toISOString()}\'`;
    } else if (typeof value === 'string') {
      const stringValue = this.dataTableService.replaceSpecialCharacters(value);
      return `'${stringValue}'`;
    } else if (typeof value === 'object') {
      return '' + value;
    } else {
      return value as string;
    }
  }

  private isDate(date: any) {
    return (new Date(date) as any) !== 'Invalid Date' && !isNaN(new Date(date) as any);
  }

  private convertToODataFilter(dataField: string, op: string, val: any, dataType: string, isArray: boolean): string {
    if (_.isArray(val)) {
      const result = [];
      for (const item of val) {
        result.push(this.convertToODataFilter(dataField, op, item, dataType, isArray));
      }
      if (!result.length) {
        result.push('true eq true');
      }
      const resultOperation = op === 'notcontains' ? 'and' : 'or';
      return '(' + result.join(' ' + resultOperation + ' ') + ')';
    } else {
      const filterToODataFilterMap = {
        ['=']: (fld, vl) => {
          return `${fld} eq ${vl}`;
        },
        ['<>']: (fld, vl) => {
          return `${fld} ne ${vl}`;
        },
        ['<']: (fld, vl) => {
          return `${fld} lt ${vl}`;
        },
        ['<=']: (fld, vl) => {
          return `${fld} le ${vl}`;
        },
        ['>']: (fld, vl) => {
          return `${fld} gt ${vl}`;
        },
        ['>=']: (fld, vl) => {
          return `${fld} ge ${vl}`;
        },
        // Use tolower for assemblers that don't return a pure SQL query, but a C# List (e.g. VmsConflicts), because filtering will be done in C# instead of SQL
        ['notcontains']: (fld, vl) => {
          return `substringof(${vl.toLowerCase()}, tolower(${fld})) eq false`;
        },
        ['contains']: (fld, vl) => {
          return `substringof(${vl.toLowerCase()}, tolower(${fld})) eq true`;
        },
        ['startswith']: (fld, vl) => {
          return `startswith(tolower(${fld}), ${vl.toLowerCase()}) eq true`;
        },
        ['endswith']: (fld, vl) => {
          return `endswith(tolower(${fld}), ${vl.toLowerCase()}) eq true`;
        }
      };

      if (val && dataType === 'number') {
        if ((val.valueOf && val.valueOf() === Infinity) || val === Infinity) {
          return 'true eq false';
        }
      }

      const value: string = this.getODataValue(val, dataType);
      let result = '';
      let field = dataField;
      if (isArray) {
        field = 'item';
        result = `${dataField}/any(${field}:`;
      }

      if (filterToODataFilterMap[op]) {
        result += filterToODataFilterMap[op](field, value);
      }

      if (isArray) {
        result += `)`;
      }

      return result;
    }
  }
}
