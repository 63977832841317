import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CodeValueGroups, NavigationService, PhxConstants, PhxLocalizationService, WindowRefService } from '../common';
import { UserContext, UserProfile, UserProfilePerDbInstance } from '../common/model';
import { AuthService } from '../common/services/auth.service';
import { PhxModalComponent } from '../common/components/phx-modal/phx-modal.component';
import { FeedbackComponent } from '../feedback/feedback.component';
import { TopNavMenuResourceKeys } from './top-nav-menu-resource-keys';
import { EventService, PhxEvents } from '../common/services/event.service';
import { MsalService } from '@azure/msal-angular';
import { forkJoin } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PortalDialogComponent } from './components/portal-dialog/portal-dialog.component';
import { BaseComponentOnDestroy } from '../common/epics/base-component-on-destroy';
import { UserBehaviorService } from '../common/services/user-behavior.service';
import DevExpress from 'devextreme';
import { InitializedEvent } from 'devextreme/ui/drop_down_button';

interface LocalProfile extends UserProfilePerDbInstance {
  Label?: string;
}

@Component({
  selector: 'app-top-nav-menu',
  templateUrl: './top-nav-menu.component.html',
  styleUrls: ['./top-nav-menu.component.less']
})
export class TopNavMenuComponent extends BaseComponentOnDestroy implements OnInit {
  @ViewChild('pickProfileModal', { static: true })
  pickProfileModal: PhxModalComponent;
  @ViewChild(FeedbackComponent, { static: true })
  feedbackComponent: FeedbackComponent;

  fullName: string;
  currentProfile: UserProfile;
  currentProfileId: number;
  currentDatabaseId: number;
  currentInstanceName: string;
  moreThanOneProfiles: boolean;

  profiles: LocalProfile[] = [];
  profilesGroupedByInstance: { instanceName: string; fullName: string; profiles: LocalProfile[]; }[] = [];
  multiProfile = false;
  multipleTenants = false;

  menuOpen = false;
  isMobile = false;

  readonly codeValueGroups = CodeValueGroups;
  resourceKeys = TopNavMenuResourceKeys;
  showProductboardPortal = false;
  profileMenuDropdownButton?: DevExpress.ui.dxDropDownButton;

  constructor(
    private authService: AuthService,
    private localizationService: PhxLocalizationService,
    private router: Router,
    private navigationService: NavigationService,
    private eventService: EventService,
    private msalService: MsalService,
    public winRef: WindowRefService,
    private dialog: MatDialog,
    private userBehaviorService: UserBehaviorService
  ) {
    super();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateIsMobile();
  }

  ngOnInit() {
    this.updateIsMobile();
    this.authService.getUserContext().then(user => {
      this.fullName = user.User.PreferredFirstName + ' ' + user.User.PreferredLastName;
    });

    forkJoin([
      this.authService.getCurrentProfile(),
      this.authService.getCurrentUser(),
      this.authService.getUserContext()
    ]).subscribe(([currentProfile, userInfo, userContext]) => {
      this.multipleTenants = (new Set(userInfo.Profiles.map(m => m.DatabaseId))).size > 1;

      this.fullName = `${this.multipleTenants ? userContext.User.CurrentTenantName + ' - ' : ''} ${userContext.User.PreferredFirstName} ${userContext.User.PreferredLastName}`;

      this.currentProfile = currentProfile;
      this.currentProfileId = currentProfile.Id;
      this.currentDatabaseId = currentProfile.DatabaseId;
      this.moreThanOneProfiles = userInfo.Profiles && userInfo.Profiles.length > 1;

      const firstDbInstanceProfile = userInfo.Profiles.find(x => x.DatabaseId === this.currentDatabaseId);
      this.currentInstanceName = firstDbInstanceProfile != null ? firstDbInstanceProfile.InstanceName : '';

      const sortedDbProfiles = this.sortProfiles(userInfo.Profiles).filter(f => f.ProfileId !== this.currentProfileId);
      this.profiles = sortedDbProfiles.map(profile => {
        return {
          ...profile,
          Label: this.multipleTenants ? `${profile.InstanceName} - ${profile.FirstName} ${profile.LastName}` : ''
        };
      });

      this.multiProfile = this.profiles.length > 1;

      this.profilesGroupedByInstance = this.GroupBy(userInfo.Profiles, 'DatabaseId').map(group => {
        return {
          instanceName: group.value[0].InstanceName,
          fullName: `${group.value[0].FirstName} ${group.value[0].LastName}`,
          profiles: group.value
        };
      });
    });
  }

  /* We have to close it, as the dropdown part gets scrolled with the page,
     could be the result of float position on many of the grandparent elements
  */
  @HostListener('window:scroll', [])
  closeProfileDropdown(): void {
    void this.profileMenuDropdownButton?.close();
  }

  onProfileMenuDropDownInitialized = (e: InitializedEvent): void => {
    this.profileMenuDropdownButton = e.component;
  };

  updateIsMobile() {
    this.isMobile = window.innerWidth <= 1279;
  }

  closeMenu() {
    this.onMenuOpenChange(false);
  }

  onMenuOpenChange(value: boolean) {
    this.menuOpen = value;
    this.navigationService.updateTopNavState(value);
  }

  async showFeedback() {
    this.closeMenu();
    /** NOTE: open product board portal - 2 portals - one for internal users and one for everyone else - see PortalDialogComponent */
    this.dialog.open(PortalDialogComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '900px',
      maxHeight: '800px'
    });
  }

  openPickProfileModal() {
    this.pickProfileModal.show();
  }

  setProfile(profile: LocalProfile) {
    this.closeMenu();
    const oldDatabaseId = this.currentDatabaseId;
    const oldProfileId = this.currentProfileId;

    this.currentDatabaseId = profile.DatabaseId;
    this.currentProfileId = profile.ProfileId;

    if (this.multipleTenants) {
      this.fullName = profile.Label;
    }

    this.authService
      .setCurrentProfile(profile.DatabaseId, profile.ProfileId)
      .then((userContext: UserContext) => {
        const p = userContext.User.UserProfiles.find(x => x.Id === profile.ProfileId);
        if (p) {
          this.currentProfile = p;
        }
        if (p.ProfileTypeId === PhxConstants.UserProfileType.Internal) {
          Promise.all([
            this.router.navigate(['/next/account/home']),
            this.userBehaviorService.clearUserSessionBehaviors()
          ]).then(() => {
            window.location.reload();
          });
        } else {
          Promise.all([
            this.router.navigate(['/next/activity-centre']),
            this.userBehaviorService.clearUserSessionBehaviors()
          ]).then(() => {
            window.location.reload();
          });
        }
        this.eventService.trigger(PhxEvents.ProfileChanged, this.currentProfile);
      })
      .catch(() => {
        this.currentDatabaseId = oldDatabaseId;
        this.currentProfileId = oldProfileId;
        this.router.navigate(['/next/activity-centre']);
      });
  }

  logoff() {
    this.closeMenu();
    Promise.all([
      this.localizationService.loadAnonymousTranslations(),
      this.userBehaviorService.clearUserSessionBehaviors()
    ]).finally(() => {
      this.authService.logout();
      if (this.msalService.instance.getAllAccounts().length > 0) {
        this.msalService.logout();
      }
      this.winRef.openUrl('/#/home', '_self');
    });
  }

  public GroupBy<T>(value: Array<T>, field: string): Array<{ key: string; value: T[]; }> {
    const groupedObj = value.reduce((prev, cur) => {
      if (!prev[cur[field]]) {
        prev[cur[field]] = [cur];
      } else {
        prev[cur[field]].push(cur);
      }
      return prev;
    }, {});
    return Object.keys(groupedObj).map(key => ({ key, value: groupedObj[key] }));
  }

  private sortProfiles(profiles: UserProfilePerDbInstance[]) {
    return profiles.sort((a, b) => {
      const diffDatabaseId = (a.DatabaseId === this.currentDatabaseId ? -1 : a.DatabaseId)
        - (b.DatabaseId === this.currentDatabaseId ? -1 : b.DatabaseId);
      const diffProfileTypeId = a.ProfileTypeId - b.ProfileTypeId;

      return diffDatabaseId !== 0 ? diffDatabaseId : diffProfileTypeId;
    });
  }
}
