import { Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '../../ngx-strongly-typed-forms/model';
import { CommonService, PhxLocalizationService } from '../..';
import { CodeValue, PhxDocumentFileUploadConfiguration, PhxDocumentFileUploadFileItemActionEventArg, PhxDocumentFileUploaderOptions, UserInfo } from '../../model';
import { IOverrideTimesheetException, IDocument } from 'src/app/common/model/override-timesheet-exception';
import { AuthService } from '../../services/auth.service';
import { PhxDocumentFileUploadComponent } from '../phx-document-file-upload/phx-document-file-upload.component';
import { DocumentService } from '../../services/document.service';
import { PhxConstants } from 'src/app/common';
import { startWith, takeUntil } from 'rxjs/operators';
import { PhxDocumentFileUploadResourceKeys } from '../phx-document-file-upload/phx-document-file-upload.resource-keys';
import { BaseComponentOnDestroy } from '../../epics/base-component-on-destroy';
import { PhxDialogComponentConfigModel } from '../phx-dialog/phx-dialog.component.model';
import { PhxDialogComponent } from 'src/app/common/components/phx-dialog/phx-dialog.component';
import { VersionComparisonService } from '../../services/version-comparison.service';

@Component({
  selector: 'app-phx-timesheet-override-exception',
  templateUrl: './phx-timesheet-override-exception.component.html',
  styleUrls: ['./phx-timesheet-override-exception.component.less']
})
export class PhxTimesheetOverrideExceptionComponent extends BaseComponentOnDestroy implements OnInit, OnChanges {
  @Input() cssClass: string;
  @Input() editable: boolean;
  @Input() overrideTimesheetExceptions: Array<IOverrideTimesheetException> = [];
  @Input() entityType: PhxConstants.EntityType;
  @Input() entityId: number;

  @ViewChild('itemFileUpload', { static: false }) itemFileUpload: PhxDocumentFileUploadComponent;
  @ViewChild(PhxDialogComponent) phxDialogComponent: PhxDialogComponent;

  @Output() removeTimesheetException: EventEmitter<number> = new EventEmitter();
  @Output() addNewTimesheetException: EventEmitter<number> = new EventEmitter();
  @Output() updatedTimesheetExceptions: EventEmitter<IOverrideTimesheetException[]> = new EventEmitter();

  inputFormGroup: FormGroup<any>;
  currentSelectedException: IOverrideTimesheetException = null;
  currentUser: UserInfo;
  documentUploadConfiguration: PhxDocumentFileUploadConfiguration;
  phxDocumentFileUploaderOptions: PhxDocumentFileUploaderOptions;
  phxConstants: typeof PhxConstants;
  html = {
    codeValueLists: {
      OverrideTimesheetExceptionCode: [] as Array<CodeValue>
    }
  };

  overrideTimesheetExceptionFormArrayValue: IOverrideTimesheetException[];
  phxDialogComponentConfigModel: PhxDialogComponentConfigModel = null;
  showAddButton: boolean;

  constructor(
    private fb: FormBuilder,
    private commonService: CommonService,
    private documentService: DocumentService,
    private authService: AuthService,
    private localizationService: PhxLocalizationService,
    @Optional() private versionComparisonService: VersionComparisonService<any, any>
  ) {
    super();
    this.phxConstants = PhxConstants;
    this.phxDocumentFileUploaderOptions = {
      queueLimit: 1,
      maxFileSize: 20 * 1024 * 1024, // 20971520 == 20 MB
      allowedMimeType: [
        'image/jpg',
        'image/jpeg',
        'image/png',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ],
      allowedFileType: [
        'image',
        'pdf',
        'doc'
      ]
    };
  }

  ngOnInit(): void {
    this.getCurrentUser();
    this.overrideExceptionFormgroupValueChangeSubscription();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.entityType) {
      this.getCodeValueListsStatic(changes.entityType.currentValue);
    }
    if (changes.overrideTimesheetExceptions) {
      this.updateForm(changes.overrideTimesheetExceptions.currentValue);
      this.setLocalProperties(changes.overrideTimesheetExceptions.currentValue);
    }
  }

  addTimesheetException() {
    this.OverrideTimesheetExceptions.push(this.createSingleOverrideExceptionFormGroup(
      { ExceptionCode: null } as IOverrideTimesheetException
    ));
    this.addNewTimesheetException.emit();
  }

  checkboxSelectedEvent(event: any, value: any) {
    const control = this.OverrideTimesheetExceptions.at(value);
    this.currentSelectedException = this.OverrideTimesheetExceptions.value.find(exception => exception?.ExceptionCode === control.value.ExceptionCode);
    this.retainCheckboxSelction(control, event);
    this.openFileUploadModal(this.currentSelectedException?.Reason);
  }

  getUploadCompleteNotification(event: PhxDocumentFileUploadFileItemActionEventArg) {
    if (event.response) {
      this.updateFileUploadDetails(event);
      this.updateSelectedOverrideException();
      this.commonService.logSuccess(this.localizationService.translate(PhxDocumentFileUploadResourceKeys.documentFileUploadComponent.uploadSuccessMessage, [event.item.file.name]));
    }
  }

  openDocument(documentId: string): void {
    return this.documentService.openDocument(documentId, true);
  }

  handleDeleteAction(index: number) {
    this.phxDialogComponentConfigModel = {
      BodyMessage: 'Are you sure you want to delete this Override Timesheet Exception?',
      Buttons: [
        {
          Id: 1,
          SortOrder: 2,
          CheckValidation: true,
          Name: 'Yes',
          Class: 'btn-primary',
          ClickEvent: () => {
            this.OverrideTimesheetExceptions.removeAt(index);
            this.removeTimesheetException.emit(index);
            if (this.OverrideTimesheetExceptions.length === 0) {
              this.addTimesheetException();
            }
          }
        },
        { Id: 2, SortOrder: 1, CheckValidation: false, Name: 'No', Class: 'btn-default' }
      ],
      ObjectDate: null,
      ObjectComment: null
    };
    this.phxDialogComponent.open();
  }

  updateForm(overrideTimesheetExceptions: Array<IOverrideTimesheetException>) {
    if (!this.inputFormGroup) {
      this.inputFormGroup = this.fb.group<any>({
        OverrideTimesheetExceptions: this.fb.array<IOverrideTimesheetException>(overrideTimesheetExceptions.map((overrideException) => this.createSingleOverrideExceptionFormGroup(overrideException))),
      });
    } else {
      if (overrideTimesheetExceptions.length > 0) {
        this.clearForm();
        const overrideExceptionsArray = this.inputFormGroup.get('OverrideTimesheetExceptions') as FormArray<IOverrideTimesheetException>;
        overrideTimesheetExceptions.forEach((overrideException) => {
          overrideExceptionsArray.push(this.createSingleOverrideExceptionFormGroup(overrideException));
        });
        this.inputFormGroup.setControl('OverrideTimesheetExceptions', overrideExceptionsArray);
      } else {
        this.clearForm();
      }
    }

    if (this.versionComparisonService) {
      this.versionComparisonService.addForComparison(this.inputFormGroup, this.versionComparisonService.customFormNames.TimesheetOverrideExceptions);
    }
  }

  clearForm() {
    const formArray: FormArray<IOverrideTimesheetException> = this.OverrideTimesheetExceptions;
    const count = 0;
    while (formArray.length !== count && count < formArray.length) {
      formArray.removeAt(count);
    }
  }


  get OverrideTimesheetExceptions(): FormArray<any> {
    return this.inputFormGroup.get('OverrideTimesheetExceptions') as FormArray<any>;
  }

  private async getCurrentUser() {
    this.currentUser = await this.authService.fetchCurrentUser();
  }


  private overrideExceptionFormgroupValueChangeSubscription() {
    this.OverrideTimesheetExceptions.valueChanges.pipe(
      startWith(this.OverrideTimesheetExceptions.getRawValue()),
      takeUntil(this.isDestroyed$)
    ).subscribe((values) => {
      this.overrideTimesheetExceptionFormArrayValue = values;
      const emitData: IOverrideTimesheetException[] = values.filter(x => x.Reason !== null && x.Document.FileStorageId !== null);
      this.updatedTimesheetExceptions.emit(emitData);
      if (values.length > 0 &&
        values[values.length - 1]?.Name !== null &&
        values.length < this.html.codeValueLists.OverrideTimesheetExceptionCode.length) {
        this.showAddButton = true;
      } else {
        this.showAddButton = false;
      }
    });
  }

  private getCodeValueListsStatic(entityType): void {
    const staticClientOverrideTimesheetExceptions: CodeValue[] = [
      {
        id: 1, groupName: 'timesheet.ClientOverrideException', parentId: null, parentCode: 'null', parentGroup: 'null',
        code: 'TimeSheetNotEnoughPurchaseOrderFunds', text: 'Not Enough PO Funds Exception', description: 'Not Enough PO Funds Exception', sortOrder: 1, Icon: null
      },
    ];

    const staticWorkorderOverrideTimesheetExceptions: CodeValue[] = [
      {
        id: 1, groupName: 'timesheet.WorkorderOverrideException', parentId: null, parentCode: 'null', parentGroup: 'null',
        code: 'Over70HoursInAWeek', text: 'Weekly total contains more than 70 hours', description: 'Weekly total contains more than 70 hours', sortOrder: 1, Icon: null
      },
      {
        id: 2, groupName: 'timesheet.WorkorderOverrideException', parentId: null, parentCode: 'null', parentGroup: 'null',
        code: 'Over7HoursOvertimeInAWeek', text: 'Timesheet hours include over 7 OT Hours/Week', description: 'Timesheet hours include over 7 OT Hours/Week', sortOrder: 2, Icon: null
      },
      {
        id: 3, groupName: 'timesheet.WorkorderOverrideException', parentId: null, parentCode: 'null', parentGroup: 'null',
        code: 'OverExpectedHoursBy75PercentInADay', text: 'Hours reported on a day exceed 175% of Expected hours',
        description: 'Hours reported on a day exceed 175% of Expected hours', sortOrder: 3, Icon: null
      },
      {
        id: 4, groupName: 'timesheet.WorkorderOverrideException', parentId: null, parentCode: 'null', parentGroup: 'null',
        code: 'HasUnitsOnStatutoryHoliday', text: 'Hours are entered on Statutory Holiday', description: 'Hours are entered on Statutory Holiday', sortOrder: 4, Icon: null
      }
    ];

    if (entityType === this.phxConstants.EntityType.WorkOrder) {
      this.html.codeValueLists.OverrideTimesheetExceptionCode = staticWorkorderOverrideTimesheetExceptions;
    }
    else {
      this.html.codeValueLists.OverrideTimesheetExceptionCode = staticClientOverrideTimesheetExceptions;
    }
  }

  getExceptionText(code: string) {
    const codevalue = this.html.codeValueLists.OverrideTimesheetExceptionCode.find(a => a.code === code);
    return codevalue ? codevalue.text : code;
  }

  private initDocumentUploadConfiguration(reason: string) {
    this.documentUploadConfiguration = new PhxDocumentFileUploadConfiguration({
      UploadTitle: 'Override Timesheet Exception Document',
      entityTypeId: this.entityType,
      entityId: this.entityId,
      customId1: 0,
      customId2: 0,
      customMethodata: null,
      description: '',
      documentTypeId: PhxConstants.DocumentType.TimesheetExceptionsDocument,
      customComment: null,
      SupportedFileExtensions: 'JPEG, JPG, PNG, PDF, DOC, DOCX',
      customUiConfig: {
        objectDate: null,
        objectComment: {
          value: reason,
          isRequired: true,
          label: 'Reason',
          helpBlock: null,
          minlength: 3,
          maxlength: 200,
        }
      },
    });
  }

  private retainCheckboxSelction(control: AbstractControl<any>, event: any) {
    const checkedException = this.OverrideTimesheetExceptions.value.find(exception => exception?.ExceptionCode === control.value.ExceptionCode);
    checkedException.IsSelected = !event.target.checked;
    control.setValue(checkedException);
    this.inputFormGroup.controls.OverrideTimesheetExceptions.patchValue(this.OverrideTimesheetExceptions.value);
  }

  private setLocalProperties(overrideTimesheetExceptions: Array<IOverrideTimesheetException>): void {
    if (!overrideTimesheetExceptions?.length && this.editable && this.inputFormGroup) {
      this.addTimesheetException();
    }
  }

  openFileUploadModal(reason: string) {
    this.initDocumentUploadConfiguration(reason);
    this.itemFileUpload.showModal();
  }

  createSingleOverrideExceptionFormGroup(overRideException: IOverrideTimesheetException): FormGroup<IOverrideTimesheetException> {
    const overRideExceptionFormGroup = this.fb.group<IOverrideTimesheetException>({
      ExceptionCode: [overRideException.ExceptionCode],
      IsSelected: [overRideException.IsSelected],
      Reason: [overRideException.Reason],
      DateTimeUtc: [overRideException.DateTimeUtc],
      Name: [overRideException.Name],
      UserProfileId: [overRideException.UserProfileId],
      Document: this.fb.group<IDocument>({
        FileStorageId: [overRideException.Document?.FileStorageId || ''],
        FileName: [overRideException.Document?.FileName || '']
      })
    });
    return overRideExceptionFormGroup;
  }

  private updateFileUploadDetails(event: PhxDocumentFileUploadFileItemActionEventArg) {
    this.currentSelectedException.Document.FileStorageId = event?.response.publicId;
    this.currentSelectedException.Document.FileName = event?.response.documentName;
    this.currentSelectedException.DateTimeUtc = new Date();
    this.currentSelectedException.Name = this.currentUser?.Profiles[0]?.FirstName + ' ' + this.currentUser?.Profiles[0]?.LastName;
    this.currentSelectedException.UserProfileId = this.currentUser?.Profiles[0]?.ProfileId;
    this.currentSelectedException.Reason = event?.customComment;
    this.currentSelectedException.IsSelected = !this.currentSelectedException.IsSelected;
  }

  private updateSelectedOverrideException() {
    const selectedIndex = this.OverrideTimesheetExceptions.controls.findIndex(x => x.value.ExceptionCode === this.currentSelectedException.ExceptionCode);
    const control = this.OverrideTimesheetExceptions.at(selectedIndex);
    control.setValue(this.currentSelectedException);
    this.inputFormGroup.controls.OverrideTimesheetExceptions.patchValue(this.OverrideTimesheetExceptions.value);
  }

}
