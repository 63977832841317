<button *ngIf="showAddButton" [disabled]="!editable" class="btn btn-default" (click)="showModal()">
  {{'common.phxDocumentFileUpload.addBtn' | phxTranslate}}
</button>

<ng-template #fileUploadTemplate>
    <div class="file-upload-modal file-upload-container">
        <div class="modal-content">
          <div class="modal-header header">
            <h4>{{'common.phxDocumentFileUpload.title' | phxTranslate}}</h4>
            <button class="btn btn-material btn-borderless pull-right close-container" (click)="close()">
              <i class="material-icons">close</i>
            </button>
          </div>
          <div class="modal-body" ng2FileDrop (fileOver)="fileOverBase($event)" [uploader]="uploader"
               [ngClass]="{'file-over': hasBaseDropZoneOver}">
            <div [ngClass]="insideModal ? '' : 'container' ">
              <div class="row message-header text-center">
                <div class="col-md-12">
                  <span [innerHTML]="configuration?.UploadTitle"></span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-center">
                  <label class="btn btn-default btn-file">
                    {{'common.phxDocumentFileUpload.addBtn' | phxTranslate}}
                    <input type="file" class="hidden" ng2FileSelect [uploader]="uploader" multiple/>
                  </label>
                  <button class="btn btn-primary margin-10-l" (click)="upload()"
                          [disabled]="disableUploadButton || !uploader?.queue?.length">
                    {{'common.phxDocumentFileUpload.uploadBtn' | phxTranslate}}
                  </button>
                </div>
                <div class="col-md-12 text-center tertiary-action"
                     *ngIf="configuration?.customUiConfig?.tertiaryAction">
                  <button class="btn btn-link" (click)="configuration.customUiConfig.tertiaryAction.action()"
                          [disabled]="configuration.customUiConfig.tertiaryAction.disabled()">
                    {{configuration.customUiConfig.tertiaryAction.text}}
                  </button>
                </div>
              </div>
              <div class="row message-accepted-file-types text-center"
                   *ngIf="configuration && configuration.SupportedFileExtensions">
                <div class="col-md-12">
                  <div>{{'common.phxDocumentFileUpload.acceptedFileTypesLabel' | phxTranslate}}:
                    {{configuration?.SupportedFileExtensions}}</div>
                </div>
                <div class="row"
                     *ngIf="configuration && uploader.queue.length > configuration?.limitMultiFileUploads">
                  <strong class="error text-danger">
                    Only {{ configuration.limitMultiFileUploads }} file(s) can be uploaded at a time
                  </strong>
                </div>
                <div class="row" *ngFor=" let message of validationMessages">
                  <strong class="error text-danger">{{ message }}</strong>
                </div>
              </div>
              <section class="files-container" *ngIf="uploader.queue.length">
                <table class="table">
                  <thead>
                  <tr>
                    <th class="col-md-6">
                      {{'common.phxDocumentFileUpload.nameColumnHeader' | phxTranslate}}
                    </th>
                    <th class="col-md-2">
                      {{'common.phxDocumentFileUpload.sizeColumnHeder'| phxTranslate}}
                    </th>
                    <th class="col-md-2">
                      {{'common.phxDocumentFileUpload.progressColumnHeader'| phxTranslate}}
                    </th>
                    <th class="col-md-1">
                      {{'common.phxDocumentFileUpload.statusColumnHeader' | phxTranslate}}
                    </th>
                    <th class="col-md-1">
                      {{'common.phxDocumentFileUpload.actionsColumnHeader' | phxTranslate}}
                    </th>
                  </tr>
                  </thead>
                  <tbody *ngFor="let item of uploader.queue;let idx = index">
                    <tr>
                    <td>
                      <i class="material-icons">insert_drive_file</i>
                      <div class="file-name">
                        <strong>{{ item?.file?.name }}</strong>
                      </div>
                    </td>
                    <td nowrap>
                      {{ item?.file?.size / 1024 / 1024 | number:'.2' }}
                      {{'common.phxDocumentFileUpload.megabyteShort'| phxTranslate}}
                    </td>
                    <td>
                      <div class="progress no-margin">
                        <div class="progress-bar progress-bar-striped active" role="progressbar"
                             [attr.aria-valuenow]="item.progress" aria-valuemin="0"
                             aria-valuemax="100"
                             [ngStyle]="{ 'width': item.progress + '%' }">
                        </div>
                      </div>
                    </td>
                    <td>
                      <span *ngIf="item.isSuccess">
                          <i class="material-icons">check</i>
                      </span>
                      <span *ngIf="item.isCancel">
                          <i class="material-icons">cancel</i>
                      </span>
                      <span *ngIf="item.isError">
                          <i class="material-icons text-red">error</i>
                      </span>
                    </td>
                    <td nowrap>
                      <button type="button" class="btn btn-default btn-material btn-borderless"
                              (click)="removeItem(item, idx)"
                              [title]="'common.phxDocumentFileUpload.remove' | phxTranslate">
                        <i class="material-icons">delete</i>
                      </button>
                    </td>
                  </tr>
                  <tr *ngIf="item.isTampered">
                    <td>E-signed document is tampered. Please discard this document and upload another.
                      <label class="btn btn-default" (click)="discardDocument(item)">Discard</label>
                    </td>
                  </tr>
                  <tr *ngIf="item.isUnverified">
                    <td>This E-signed document is unverified. Do you want to continue uploading the
                      document?
                      <label class="btn btn-default btn-warning"
                             (click)="continueDocument(item)">Continue</label>
                      <label class="btn btn-default" (click)="discardDocument(item)">Discard</label>
                    </td>
                  </tr>
                  <tr *ngIf="configuration?.customUiConfig?.objectDate">
                    <td colspan="2">
                      <div class="form-group">
                        <label class="col-lg-3 control-label label-to-right"
                               [ngClass]="configuration?.customUiConfig?.objectDate?.isRequired && !configuration.customUiConfig.objectDate.value? 'req-lab-right' : ''">
                          {{configuration.customUiConfig.objectDate.label}}
                        </label>
                        <div class="col-lg-4 d-block">
                          <app-phx-date-box 
                                            [(ngModel)]="configuration?.customUiConfig?.objectDate.value"
                                            type="date"
                                            [required]="configuration?.customUiConfig.objectDate.isRequired"
                                            (valueChanged) = "handleStartUploadButton()"  >
                          </app-phx-date-box>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="configuration?.customUiConfig?.objectComment">
                    <td colspan="2">
                      <div class="form-group">
                        <label class="col-lg-3 control-label label-to-right" [ngClass]="configuration.customUiConfig.objectComment.isRequired
                                                  && (
                                                      !configuration.customUiConfig.objectComment.value?.trim()
                                                      || configuration.customUiConfig.objectComment.value.length < configuration.customUiConfig.objectComment.minlength
                                                      || configuration.customUiConfig.objectComment.value.length > configuration.customUiConfig.objectComment.maxlength
                                                   )
                                                  ? 'req-lab-right' : '' ">
                          {{configuration.customUiConfig.objectComment.label}}
                        </label>
                        <div class="col-lg-4">
                          <input class="form-control" name="objectComment"
                                 maxlength="{{configuration.customUiConfig.objectComment.maxlength}}"
                                 minlength="{{configuration.customUiConfig.objectComment.minlength}}"
                                 required="configuration.customUiConfig.objectComment.isRequired"
                                 [(ngModel)]="configuration.customUiConfig.objectComment.value"
                                 (ngModelChange)="handleStartUploadButton()"/>
                          <span class="help-block">{{configuration.customUiConfig.objectComment.helpBlock}}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="configuration?.customUiConfig?.objectDocumentType">
                    <td colspan="2">
                      <div class="form-group">
                        <label class="col-lg-3 control-label label-to-right"
                               [ngClass]="configuration.customUiConfig.objectDocumentType.isRequired
                                                       && (configuration.customUiConfig.objectDocumentType.value === null)
                                                       && !item.DocumentTypeId ? 'req-lab-right' : ''">
                          {{configuration.customUiConfig.objectDocumentType.label}}
                        </label>
                        <div class="col-lg-4">
                          <app-phx-select-box [items]="docTypeList" textField="text"
                                              [(ngModel)]="item.DocumentTypeId" valueField="id">
                          </app-phx-select-box>
                          <span class="help-block">{{configuration?.customUiConfig.objectDocumentType.helpBlock}}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="configuration?.documentSubTypes?.length > 1">
                    <td colspan="2">
                      <div class="form-group">
                        <label class="col-lg-3 control-label label-to-right" [ngClass]="{ 'req-lab-right': configuration.customUiConfig.complianceDocumentSubTypes[idx].value === null 
                              && !configuration.customUiConfig.complianceDocumentSubTypes[idx].value }">
                          {{configuration.customUiConfig.complianceDocumentSubTypes[idx].label}}
                        </label>
                        <div class="col-lg-4 d-block">
                          <app-phx-select-box [items]="configuration.documentSubTypes" textField="name"
                            [(ngModel)]="configuration.customUiConfig.complianceDocumentSubTypes[idx].value"
                            valueField="id" (ngModelChange)="handleStartUploadButton()">
                          </app-phx-select-box>
                          <span
                            class="help-block">{{configuration?.customUiConfig.complianceDocumentSubTypes.helpBlock}}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                </table>
                <div>
                  {{'common.phxDocumentFileUpload.progressLabel' | phxTranslate}}:
                  <div class="progress">
                    <div class="progress-bar progress-bar-striped active" role="progressbar"
                         [attr.aria-valuenow]="uploader.progress" aria-valuemin="0"
                         aria-valuemax="100"
                         [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
    </div>
</ng-template>

<app-phx-document-file-upload-preview-modal (uploadCurrentFile)="uploadAfterPreview($event)"
  (getNewFile)="replacePreviewDocument()"></app-phx-document-file-upload-preview-modal>