import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import DataSource from 'devextreme/data/data_source';
import { GlobalSearchService, GlobalSearchState } from '../../../services/global-search-service';
import { AzSearchStoreExtension } from '../../../az-search-store-extension/az-search-store-extension';
import { GlobalSearchBarService } from '../../services/global-search-bar.service';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { BaseComponentOnDestroy } from '../../../../common/epics/base-component-on-destroy';
import { GlobalSearchConstants } from '../../../models/global-search-constants';
import { AzSearchStoreExtensionType, IActiveGsAzSearchStoreExtensionInfo } from '../../../models/global-search';
import { ISearchBarType } from '../../../interfaces/global-search';

import { ApiService } from '../../../../common';
import { environment } from '../../../../../environments/environment';
import { ReportGlobalSearchParams } from '../../../../common/model/report-global-search-params.model';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search-bar.component.html',
  styleUrls: ['./global-search-bar.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalSearchBarComponent extends BaseComponentOnDestroy implements OnInit, OnDestroy {
  searchControl: UntypedFormControl;
  data: Array<any> = [];
  dataSource: DataSource;
  searchText = '*';
  azstore: AzSearchStoreExtension;
  availableOptions = GlobalSearchConstants.GsSearchBarSearchTypes;
  selectedOption: ISearchBarType;
  azSearchStoreExtensionType = AzSearchStoreExtensionType;
  searchTypeToSearchOn: AzSearchStoreExtensionType;
  showSearchSuggestions = false;
  searchTypeOnActiveStore: AzSearchStoreExtensionType;
  searchTypeOnSuggestionStore: AzSearchStoreExtensionType;

  searchIsDisabled = true;

  private reportApiEndpoint = environment.reportServiceApiEndpoint;

  @ViewChild('searchInputElement', { static: false }) searchInputElement: ElementRef<HTMLInputElement>;

  constructor(
    private cdr: ChangeDetectorRef,
    private globalSearchBarService: GlobalSearchBarService,
    private globalSearchService: GlobalSearchService,
    private apiService: ApiService
  ) {
    super();
  }

  ngOnInit() {
    this.searchControl = new UntypedFormControl('');
    this.globalSearchBarService.searchBoxRefresh$
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(() => {
        this.searchControl.reset('', { emitEvent: false, onlySelf: true });
        if (this.searchInputElement && this.searchInputElement.nativeElement) {
          this.searchInputElement.nativeElement.focus();
        }
        this.azstore.searchAndResetFacets();
      });

    const activeStoreInfo = GlobalSearchState.ActiveGlobalAzSearchStoreExtensionInfo;
    this.azstore = activeStoreInfo.AzSearchStoreExtension;
    this.searchTypeOnActiveStore = GlobalSearchState.ActiveGlobalAzSearchStoreExtensionInfo.Type;
    this.searchTypeOnSuggestionStore = GlobalSearchState.ActiveGlobalAzSearchStoreExtensionInfo.Type;
    this.selectedOption = this.getAvailableOptionOfType(activeStoreInfo.Type);

    this.azstore.setSuggestCallback(this.suggestCallback);
    this.azstore.setSearchCallback(this.searchCallback);

    GlobalSearchState.ActiveGlobalAzSearchStoreExtensionInfo$
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((azSearchStoreExtensionInfo: IActiveGsAzSearchStoreExtensionInfo) => {
        this.selectedOption = this.getAvailableOptionOfType(azSearchStoreExtensionInfo.Type);
        this.searchTypeOnActiveStore = azSearchStoreExtensionInfo.Type;
        this.cdr.detectChanges();
      });
    this.searchControl.valueChanges
      .pipe(debounceTime(200),
        takeUntil(this.isDestroyed$))
      .subscribe(val => {
        this.searchIsDisabled = val?.replace(/ /g, '') === '';
        this.showSearchSuggestions = true;
        this.buildDataSource(val);
      });
  }

  getSearchTypeName() {
    if (this.searchTypeOnActiveStore === AzSearchStoreExtensionType.WorkOrder) {
      return 'AssignmentSearch';
    }
    if (this.searchTypeOnActiveStore === AzSearchStoreExtensionType.Payment) {
      return 'PaymentSearch';
    }
    if (this.searchTypeOnActiveStore === AzSearchStoreExtensionType.Transaction) {
      return 'TransactionSearch';
    }
  }

  searchCallback = async (state, postBody): Promise<Response> => {
    const searchType = this.getSearchTypeName();
    const stateFacets = postBody.facets;

    const searchRequest: ReportGlobalSearchParams = {
      search: postBody.search,
      facets: stateFacets,
      filter: postBody.filter,
      orderBy: postBody.orderby,
      top: postBody?.top,
      skip: postBody.skip
    };


    return new Promise(resolve => {
      resolve(
        this.apiService.fetchRequest(`GlobalSearch/${searchType}/search`,
          {
            headers: {
              accept: 'application/json, text/plain, */*',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'content-type': 'application/json',
            },
            body: JSON.stringify(searchRequest),
            method: 'POST'
          }, this.reportApiEndpoint, false)
      );
    });
  };

  suggestCallback = async (state, postBody): Promise<Response> => {
    const searchType = this.getSearchTypeName();

    return await new Promise(resolve => {
      resolve(
        this.apiService.fetchRequest(`GlobalSearch/${searchType}/suggestions?search=${this.searchText}&$select=${postBody.select}`,
          {
            headers: {
              accept: 'application/json, text/plain, */*',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'content-type': 'application/json',
            },
            method: 'GET'
          }, this.reportApiEndpoint, false)
      );
    });
  };

  search() {
    if(!this.searchIsDisabled){
     if (this.searchTypeOnActiveStore !== this.searchTypeToSearchOn) {
        this.switchSearchType(this.searchTypeToSearchOn);
      }
      this.showSearchSuggestions = false;
      this.azstore.setInput(this.searchText);
      this.globalSearchService.trackedExecuteSearchFromSearchBar();
    }
  }

  hideSuggestionsList() {
    setTimeout(() => {
      this.showSearchSuggestions = false;
      this.cdr.detectChanges();
    }, 300);
  }

  setSearchTypeToSearchOn(searchType: AzSearchStoreExtensionType) {
    this.searchTypeToSearchOn = searchType; // Only switch to the search type when we execute a search.
    this.searchTypeOnSuggestionStore = searchType; // Only switch to the search type when we execute a search.
    this.selectedOption = this.getAvailableOptionOfType(searchType);

    switch (searchType) {
      case AzSearchStoreExtensionType.WorkOrder: {
        this.azstore = GlobalSearchState.AzSearchStoreExtensionWO;
        break;
      }
      case AzSearchStoreExtensionType.Transaction: {
        this.azstore = GlobalSearchState.AzSearchStoreExtensionTransaction;
        break;
      }
      case AzSearchStoreExtensionType.Payment: {
        this.azstore = GlobalSearchState.AzSearchStoreExtensionPayment;
        break;
      }
    }

    this.azstore.setSuggestCallback(this.suggestCallback);
    this.azstore.setSearchCallback(this.searchCallback);

    if (this.searchTypeToSearchOn !== this.searchTypeOnActiveStore) {
      this.search();
    }
    this.buildDataSource(this.searchControl.value);
  }

  private switchSearchType(searchType: AzSearchStoreExtensionType) {
    switch (searchType) {
      case AzSearchStoreExtensionType.WorkOrder: {
        this.globalSearchService.activateWorkOrderGlobalSearchInState();
        break;
      }
      case AzSearchStoreExtensionType.Transaction: {
        this.globalSearchService.activateTransactionGlobalSearchInState();
        break;
      }
      case AzSearchStoreExtensionType.Payment: {
        this.globalSearchService.activatePaymentGlobalSearchInState();
        break;
      }
    }
  }

  private getAvailableOptionOfType(searchType: AzSearchStoreExtensionType): ISearchBarType {
    return this.availableOptions.find(opt => opt.searchType === searchType);
  }

  private buildDataSource(searchText: string) {
    this.searchText = searchText || '*';
    this.azstore.setSuggestionInput(this.searchText);
    this.globalSearchService.trackedSuggest(this.azstore).then(() => {
      const state = this.azstore.getState();
      this.data = (state && state.suggestions && state.suggestions.suggestions) || [];
      if (this.data) {
        this.data.forEach(item => (item.SearchText = item['@search.text']));
      }
      this.cdr.detectChanges();
    });
  }
}
