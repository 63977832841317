import { Component, Input } from '@angular/core';
import { rateTypeAbbreviations } from 'src/app/common/constants/timesheet.const';
import { CodeValueGroups, PhxConstants } from '../../model';

@Component({
  selector: 'app-time-sheet-overtime-breakdown',
  templateUrl: './time-sheet-overtime-breakdown.component.html',
  styleUrls: ['./time-sheet-overtime-breakdown.component.less']
})
export class TimeSheetOvertimeBreakdownComponent {
  @Input() overtimeTimeSheetDetails?: Array<{ RateTypeId: number; UnitAmount: number; }>;
  public readonly overtimeRateTypesToAlertInOvertimeBreakdown = new Set([PhxConstants.RateType.Overtime, PhxConstants.RateType.DoubleTime]);
  public readonly rateTypeAbbreviations = rateTypeAbbreviations;
  public readonly codeValueGroups = CodeValueGroups;
  public readonly hourUnit = PhxConstants.RateUnit.Hour;
}
