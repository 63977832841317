<ul
  *ngIf="overtimeTimeSheetDetails"
  class="overtime-breakdown"
>
  <li *ngFor="let details of overtimeTimeSheetDetails">
    <span
      class="overtime-breakdown-item"
      [class.alert-warning]="overtimeRateTypesToAlertInOvertimeBreakdown.has(details.RateTypeId)"
    >
      <b>{{ details.UnitAmount | number : '1.2-2' }} {{ hourUnit | phxTypedTranslate : 'RateUnit' }}</b>
      {{ rateTypeAbbreviations[details.RateTypeId] ?? (details.RateTypeId | CodeValue : codeValueGroups.RateType : 'text') }}
    </span>
  </li>
</ul>
