import { PhxLocalizationService } from '../../services/phx-localization.service';
import { CodeValueService } from '../../services/code-value.service';
import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import { CodeValueGroups, PhxConstants } from '../../index';
import * as _ from 'lodash';
import { PhxDataTableConfiguration, PhxDataTableColumn, PhxDataTableStateSavingMode } from '../../model';
import { PhxDataTableComponent } from '../phx-data-table/phx-data-table.component';
import { PhoenixCommonModuleResourceKeys } from '../../PhoenixCommonModule.resource-keys';
import { WorkflowService } from '../../services/workflow.service';

/** The component works with the legacy Workflow V1 */
@Component({
  selector: 'app-phx-workflow-event-history',
  templateUrl: './phx-workflow-event-history.component.html',
  styleUrls: ['./phx-workflow-event-history.component.less']
})
export class PhxWorkflowEventHistoryComponent implements OnInit, OnChanges {
  @Input() entityTypeId: PhxConstants.EntityType.RemittanceTransactionBatch | PhxConstants.EntityType.AccessSubscription;
  @Input() entityId: number;
  @Input() approverName: string;
  @ViewChild('masterDataTable', { static: true }) masterDataTable: PhxDataTableComponent;

  dataTableConfiguration: PhxDataTableConfiguration = new PhxDataTableConfiguration({
    columnHidingEnabled: true,
    masterDetailTemplateName: 'items',
    enableMasterDetail: true,
    stateSavingMode: PhxDataTableStateSavingMode.None,
    showFilter: false,
    showColumnChooser: false,
    showSearch: false,
    showGrouping: false,
    showTotalCount: false,
    showBorders: false,
    rowAlternationEnabled: false
  });

  detailDataTableConfiguration: PhxDataTableConfiguration = new PhxDataTableConfiguration({
    columnHidingEnabled: true,
    stateSavingMode: PhxDataTableStateSavingMode.None,
    showFilter: false,
    showColumnChooser: false,
    showSearch: false,
    showGrouping: false,
    showTotalCount: false,
    showBorders: false,
    rowAlternationEnabled: false
  });

  columns: Array<PhxDataTableColumn>;

  detailColumns: Array<PhxDataTableColumn>;
  readonly phxConstants = PhxConstants;
  workflowItems: any[];
  workflowCount: number = 0;
  loadItemsPromise: any;
  readonly unique = Math.floor(Math.random() * 1000000);

  constructor(
    private codeValueService: CodeValueService,
    private workflowService: WorkflowService,
    private localizationService: PhxLocalizationService
  ) {}

  buildColumns() {
    this.columns = [
      new PhxDataTableColumn({
        dataField: 'action',
        caption: this.localizationService.translate(PhoenixCommonModuleResourceKeys.phxWorkflowEventHistory.actionColumnHeader),
        width: 200
      }),
      new PhxDataTableColumn({
        dataField: 'started',
        caption: this.localizationService.translate(PhoenixCommonModuleResourceKeys.phxWorkflowEventHistory.startedColumnHeader),
        dataType: 'date',
        cellTemplate: 'dateCellTemplate'
      }),
      new PhxDataTableColumn({
        dataField: 'completed',
        caption: this.localizationService.translate(PhoenixCommonModuleResourceKeys.phxWorkflowEventHistory.completedColumnHeader),
        dataType: 'date',
        cellTemplate: 'dateCellTemplate'
      }),
      new PhxDataTableColumn({
        dataField: 'approver',
        caption: this.localizationService.translate(PhoenixCommonModuleResourceKeys.phxWorkflowEventHistory.approverColumnHeader)
      })
    ];

    this.detailColumns = [
      new PhxDataTableColumn({
        dataField: 'TaskTemplateDisplayHistoryEventName',
        caption: this.localizationService.translate(PhoenixCommonModuleResourceKeys.phxWorkflowEventHistory.taskColumnHeader)
      }),
      new PhxDataTableColumn({
        dataField: 'CreatedDatetime',
        caption: this.localizationService.translate(PhoenixCommonModuleResourceKeys.phxWorkflowEventHistory.assignedOnColumnHeader),
        dataType: 'date',
        cellTemplate: 'dateCellTemplate'
      }),
      new PhxDataTableColumn({
        dataField: 'LastModifiedDatetime',
        caption: this.localizationService.translate(PhoenixCommonModuleResourceKeys.phxWorkflowEventHistory.completedOnColumnHeader),
        dataType: 'date',
        cellTemplate: 'dateCellTemplate'
      }),
      new PhxDataTableColumn({
        dataField: 'LastModifiedByContactName',
        caption: this.localizationService.translate(PhoenixCommonModuleResourceKeys.phxWorkflowEventHistory.completedByColumnHeader)
      }),
      new PhxDataTableColumn({
        dataField: 'Comment',
        caption: this.localizationService.translate(PhoenixCommonModuleResourceKeys.phxWorkflowEventHistory.notesColumnHeader)
      })
    ];
  }

  spliceItemsByAt(items) {
    return items;
  }

  splitUserActions(items: Array<any>) {
    const result = [];
    let temp = [];
    let k = 0;
    for (let i = 0; i < items.length; i++) {
      items[i].TaskResultName = this.codeValueService.getCodeValueText(items[i].TaskRoutingTaskResultId, CodeValueGroups.TaskResult);
      items[i].isLastInGroup = false;
      items[i].isFirstInGroup = false;
      if (items[i].TaskTemplateDisplayHistoryEventName === 'System Review') {
        items[i].LastModifiedByContactName = this.localizationService.translate(PhoenixCommonModuleResourceKeys.generic.system);
      }
      if (!items[i].TaskRoutingTaskResultId) {
        items[i].LastModifiedDatetime = null;
        items[i].LastModifiedByContactName = null;
      }
      const idx = items[i].TaskTemplateDisplayHistoryEventName.indexOf('@');
      if (idx === 0) {
        const name = items[i].TaskTemplateDisplayHistoryEventName.substring(1);
        items[i].WorkflowTemplateName = items[i].TaskTemplateDisplayHistoryEventName = name;
        items[i].isFirstInGroup = true;
        items[i - 1].isLastInGroup = true;
        result[k] = temp;
        k++;
        temp = [];
      }
      temp.push(items[i]);
    }

    if (temp.length > 0) {
      result[k] = temp;
    }
    return result;
  }

  workflowHistoryModelBuilder(items) {
    this.workflowCount = items.length;

    const eachCurrentItem = item => {
      item.Comment = (item.TaskComments as any[])
        .filter((taskComment: any) => taskComment.SystemCommentId || (taskComment.Comment && taskComment.Comment.length > 0))
        .map((taskComment: any) => {
          return this.localizationService.translate(taskComment.Comment);
        })
        .join(', ');
    };

    for (let i = 0; i < items.length; i++) {
      const currentItem = items[i];
      if (currentItem && currentItem.length > 0) {
        _.each(currentItem, eachCurrentItem);
        const len = currentItem.length - 1;
        const approver = currentItem[len].LastModifiedByContactName;
        this.workflowItems[i] = {
          action: currentItem[0].WorkflowTemplateName,
          started: currentItem[0].CreatedDatetime,
          completed: currentItem[len].LastModifiedDatetime,
          approver: approver ? approver : '-',
          task: currentItem[len].TaskResultName ? '-' : currentItem[len].TaskTemplateDisplayHistoryEventName,
          status: this.codeValueService.getCodeValueText(currentItem[len].TaskStatusId, CodeValueGroups.TaskResult),
          items: currentItem
        };

        if (
          this.workflowItems[i].approver === null ||
          (currentItem[0].isFirstInGroup === true && currentItem.length === 1 && currentItem[0].isLastInGroup === false)
        ) {
          this.workflowItems[i].completed = null;
        }

        if (
          this.workflowItems[i].approver === null ||
          currentItem[len].isLastInGroup === true ||
          currentItem[len].TaskTemplateDisplayHistoryEventName === 'Approved'
        ) {
          this.workflowItems[i].approver = '-';
        }
      }
    }
  }

  getHistory() {
    this.workflowCount = 0;
    this.workflowItems = [];
      this.loadItemsPromise = this.workflowService.getWorkflowEventsHistory(this.entityTypeId, this.entityId).then((response: any) => {
          response.Items = this.spliceItemsByAt(response.Items);
          const Items = this.splitUserActions(response.Items || []);
          this.workflowHistoryModelBuilder(Items);
          this.loadItemsPromise = null;
          this.masterDataTable.refresh();
      });
  }

  ngOnInit() {
    this.buildColumns();
  }

  ngOnChanges(): void {
    this.getHistory();
  }

  public reload() {
    this.getHistory();
  }
}
